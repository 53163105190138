import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Assignees from 'common/components/assignee/assignees';
import Status from 'common/components/status';
import { useAssigneesList, useFieldAttributes, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import useGetFieldPermissions from 'common/utils/hooks/useGetFieldPermissions';
import { BarLoader } from 'features/queue/grid/defaultCellRender/SkeletonLoader';
import { setForceFloatingTicketShowError, setForceShowError } from 'store/fieldValidationsSlice';
import { isRequiredField } from 'common/utils/fieldUtils';
import { TRACK_EVENTS } from 'constants/trackEvents';
import useShowNoEditPermissionPopup from '../../../common/utils/hooks/useShowNoEditPermissionPopup';
import Actions from '../actions';
import Share from '../actions/share';
import {
  headerActionsMenuStyles,
  HeaderItemLabel,
  LabelWrap,
  StyledFlexibleContent,
  StyledRightButtons,
  StyledStatusActionsContainer,
} from '../style';
import useTexts from './useTexts';
import TemplateName from '../actions/templateName';
import { SR_PANEL_CONSTANTS, STATUS_FIELD_ID } from '../../srPanel/consts';
import { useArchiveTicket } from '../../../remote-state/archive';

function HeaderFooterRight(props) {
  const {
    className,
    onTicketStatusChange,
    srObject,
    onBackButtonClick,
    isTicketDisabled,
    template,
    isValidTicket,
    isNewSR,
    updateFieldValidation,
    isTicketPanel,
    isFloatingTicketPanel,
    handleFieldChange,
    handleTicketLock,
    handleDirty,
    updateOnExit,
    forceStopuseStateStore,
    isChildSr,
    srParentId,
    childSrsCount,
    srTypeText,
  } = props;
  const { srType, id: srId, assignee: assigneeName, assignedGroup, status, archive } = srObject;
  const isArchived = archive === 1;
  const { assigneeText, statusText } = useTexts();
  const [dropdownGroupName, setDropdownGroupName] = useState();
  const dispatch = useDispatch();
  const { invalidateArchivePermissions } = useArchiveTicket();

  const { showNoPermissionPopup } = useShowNoEditPermissionPopup();
  const {
    admins: { data: admins },
    groups: { data: groups },
    adminsByGroup: { data: dropdownGroupList },
  } = useAssigneesList(dropdownGroupName);
  const assigneeField = template?.header?.find((field) => field.fieldId === 62);
  const getAssigneePermissionsRules = useGetFieldPermissions(assigneeField);

  const statusField = template?.header?.find((field) => field.fieldId === 8);
  const getStatusPermissionsRules = useGetFieldPermissions(statusField);

  const { data, isFetched: isStatusValuesFetched } = useFieldAttributes([{ fieldId: STATUS_FIELD_ID, srType }])?.[0] || {};
  const statusList = data?.values;

  const isNoPermissionEditStatus = getStatusPermissionsRules?.disabled || statusField?.readOnly;
  const isStatusDisabled = isTicketDisabled || Boolean(!isValidTicket) || isChildSr || isNoPermissionEditStatus;

  const assigneeRequired = isRequiredField(status, assigneeField?.required, assigneeField?.requiredStatuses);
  const isAssigneeFieldDisabled = isTicketDisabled || getAssigneePermissionsRules?.disabled || assigneeField?.readOnly;

  useEffect(() => {
    if (isStatusDisabled) dispatch(isNewSR ? setForceFloatingTicketShowError(true) : setForceShowError(true));
  }, [isStatusDisabled, isNewSR, dispatch]);

  const handleStatusChange = useCallback(
    (updatedStatus) => {
      if (onTicketStatusChange) {
        onTicketStatusChange(updatedStatus);
      }
      const identifier = updatedStatus.id || updatedStatus.valueKey;
      handleFieldChange({ status: Number(identifier) });
      updateOnExit(true);

      // handle 'Open' statuses change only
      // 'Closed' statuses changes are handled in useEditor -> resolution place
      if (updatedStatus.valueClass !== SR_PANEL_CONSTANTS.CLOSED_CLASS) {
        invalidateArchivePermissions(isArchived, srId);
      }
    },
    [onTicketStatusChange, handleFieldChange, updateOnExit, invalidateArchivePermissions, isArchived, srId],
  );
  const handleAssigneeChange = (e) => {
    handleFieldChange(e);
    updateOnExit(true);
  };

  const ticketPermissions = useTicketPermissions('delete', srId);
  const ticketArchivePermissions = useTicketPermissions(isArchived ? 'restore' : 'archive', srId);
  const disabledDeleteAction = !(ticketPermissions ? ticketPermissions.data : false);
  const disabledArchiveAction = !ticketArchivePermissions?.data;

  const currentAssigned = useMemo(() => {
    const assigneeUserFiltered = admins?.filter((assignee) => assigneeName === assignee.userName);
    const profileImage =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].profileImage : undefined;
    const calculatedUserName =
      assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].calculatedUserName : undefined;
    const assigneeId = assigneeUserFiltered && assigneeUserFiltered.length ? assigneeUserFiltered[0].id : undefined;
    const groupsFiltered = groups?.filter((group) => assignedGroup === group.groupName);
    const groupId = groupsFiltered && groupsFiltered.length ? groupsFiltered[0].id : undefined;
    return {
      admin: { userName: assigneeName, id: assigneeId, profileImage, calculatedUserName },
      group: { groupName: assignedGroup, id: groupId },
    };
  }, [admins, groups, assignedGroup, assigneeName]);

  return (
    <div className={`footer-right ${className}`}>
      <LabelWrap className="assignee-label-wrap">
        <StyledFlexibleContent>
          <HeaderItemLabel className={`${className} assignee-label`}>{assigneeText}</HeaderItemLabel>
          <Assignees
            currentAssigned={currentAssigned}
            required={assigneeRequired}
            bigAvatar
            disabled={isAssigneeFieldDisabled}
            key={assigneeName || assignedGroup}
            admins={admins}
            groups={groups}
            dropdownGroupName={dropdownGroupName}
            dropdownGroupList={dropdownGroupList}
            setDropdownGroupName={setDropdownGroupName}
            isNewSR={isNewSR}
            handleDirty={handleDirty}
            isTicketPanel={isTicketPanel}
            isFloatingTicketPanel={isFloatingTicketPanel}
            onFocus={handleTicketLock}
            onChange={handleAssigneeChange}
            updateFieldValidation={updateFieldValidation}
          />
        </StyledFlexibleContent>
      </LabelWrap>
      <StyledStatusActionsContainer>
        <LabelWrap data-cy="status-list">
          <HeaderItemLabel className={className}>{statusText}</HeaderItemLabel>
          {!isStatusValuesFetched ? (
              <BarLoader width={50} showBorder />
          ) : (
            <Status
              fieldId={statusField?.fieldId}
              status={status}
              statusList={statusList}
              onChange={handleStatusChange}
              onFocus={handleTicketLock}
              disabled={isStatusDisabled}
              srType={srType}
              handleDirty={handleDirty}
              isFloatingTicketPanel={isFloatingTicketPanel}
              updateFieldValidation={updateFieldValidation}
              forceStopuseStateStore={forceStopuseStateStore}
              srId={srId}
              srTypeText={srTypeText}
              isChildSr={isChildSr}
              srParentId={srParentId}
              childSrsCount={childSrsCount}
              {...(isNoPermissionEditStatus ? { onDisabledClick: showNoPermissionPopup } : {})}
            />
          )}
        </LabelWrap>
        {!isFloatingTicketPanel && (
          <StyledRightButtons>
            <Share />
            <Actions
              footer={<TemplateName template={template} />}
              menuStyles={headerActionsMenuStyles}
              srId={srId}
              srType={srType}
              onClickParent={onBackButtonClick}
              disabledDeleteAction={disabledDeleteAction || isTicketDisabled}
              disabledArchiveAction={disabledArchiveAction}
              deleteTicketSx={{ '.delete-ticket': { justifyContent: 'end', minWidth: 0 } }}
              pendoEvent={TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_TICKET_CLICK}
              isFromQueue={false}
              isArchived={isArchived}
            />
          </StyledRightButtons>
        )}
      </StyledStatusActionsContainer>
    </div>
  );
}
export default memo(HeaderFooterRight);
