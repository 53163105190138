import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { useFieldAttributes, useHandleTicketLock, useTicketLockStatus } from 'remote-state/ticketServiceHooks';
import { updateFieldValidationStatus } from 'store/fieldValidationsSlice';
import { selectActiveUser } from 'store/userSlice';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import useGetFieldPermissions from 'common/utils/hooks/useGetFieldPermissions';
import { TitleField } from 'common/components/controls/TitleField/index';
import SrLockHeader from 'features/header/sr-lock-header';
import { APP_CONSTANTS } from 'constants/app';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import { isRequiredField } from 'common/utils/fieldUtils';
import { getIsPreviewSr } from 'common/utils/srUtils';
import { PERFORMANCE_MEASURE } from 'constants/performanceMeasure';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TRACK_EVENTS } from 'constants/trackEvents';
import { ReactComponent as SrLinkIcon } from 'images/icons/sr-linked-indicator.svg';
import { ReactComponent as ArchiveIcon } from 'images/icons/archive-badge-icon.svg';
import { ReactComponent as MergeIcon } from 'images/icons/small_merge.svg';
import { MIDDLE_PANEL_TAB_NAMES } from 'features/resolutionPanel/middlePanel/constants';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import { useTabs } from 'features/resolutionPanel/middlePanel/auditLog/hooks/useTabs';
import { useIsSrChildOrParent } from 'features/resolutionPanel/middlePanel/relatedItems/hooks/useRelatedItems';
import { selectJourneyFilters } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { RELATIONSHIP_TYPES } from 'features/resolutionPanel/middlePanel/relatedItems/constants';
import useShowNoEditPermissionPopup from 'common/utils/hooks/useShowNoEditPermissionPopup';
import { QUERIES_KEYS } from 'constant';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { useApplicationData } from 'remote-state/applicationHooks';
import HeaderFooterRight from './header-footer-right';
import SrId from './sr-id';
import { InlineFieldContainerWrapper, StyledHeader } from './style';
import HeaderTags from './header-tags';
import GoBack from './go-back';
import useTexts, { MERGED_SR_AS_PRIMARY, MERGED_SR_AS_SECONDARY } from './useTexts';
import NextOrPrevSR from './next-or-prev-sr';
import { selectDirection } from '../../store/globalSlice';

export const extractHeaderDefaultValue = (header, key) => {
  const field = header?.find((f) => f.fieldName === key);
  return field?.defaultValue;
};

const Header = memo((props) => {
  const {
    srObject,
    handleSaveSR,
    template,
    isNewSR,
    isTicketPanel,
    isFloatingTicketPanel,
    isValidTicket,
    handleDirty,
    autoFocus,
    onTicketStatusChange,
    hasEditPermissions = true,
    isDisabled,
    className = '',
    forceSrId = null,
    forceStopuseStateStore,
    onTemplateChanged,
    isArchived,
  } = props;
  const router = useRouter();
  const queryClient = useQueryClient();
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI);
  const scheduleUpdateAssociatedFields = useRef();
  const { srType, id: srId, title: srTitle, priority, status } = srObject;
  const srTypeValue = SR_TYPE_CODE_MAP[srType];
  const { srTypeText, srTypeNewText, archivedText, getMergedSrIndicationText } = useTexts(srTypeValue);
  const dispatch = useDispatch();
  const [shouldUpdateSr, setShouldUpdateSr] = useState(false);
  const { handleActiveTab } = useAuditLog();
  const { tabs: ticketTabs } = useTabs({ srId });
  const { tab: selectedTab } = useSelector(selectJourneyFilters);
  const trackEvents = useAnalytics();
  const relatedItemsTabIndex = ticketTabs?.findIndex((tab) => tab.name === MIDDLE_PANEL_TAB_NAMES?.RELATED_ITEMS) || 0;
  const direction = useSelector(selectDirection);
  const {
    isSrChildOrParent,
    relationshipText,
    relationship: relationshipId,
    parentId,
    childSrsCount,
  } = useIsSrChildOrParent(srId);
  const isRelatedItemsTabSelected = relatedItemsTabIndex === selectedTab;
  // To check if category fields are editable, enough to check only one of the categories since they all have the same permissions.
  const categoryField = template?.header?.find((field) => field.fieldId === 59);
  const priorityField = template?.header?.find((field) => field.fieldId === 58);
  const titleField = template?.header?.find((field) => field.fieldId === 57);
  const priorityRequired = true;
  const categoryRequired = isRequiredField(status, categoryField?.required, categoryField?.requiredStatuses);
  const titleRequired = isRequiredField(status, titleField?.required, titleField?.requiredStatuses);
  const categoriesValues = useMemo(
    () => ({
      primaryCategory: srObject?.category?.firstLevelKey || srObject?.primaryCategory,
      secondaryCategory: srObject?.category?.secondLevelKey || srObject?.secondaryCategory,
      thirdLevelCategory: srObject?.category?.thirdLevelKey || srObject?.thirdLevelCategory,
    }),
    [srObject],
  );

  const isMergedSR = useMemo(
    () => srObject?.mergedAs === MERGED_SR_AS_PRIMARY || srObject?.mergedAs === MERGED_SR_AS_SECONDARY,
    [srObject],
  );

  const getPriorityPermissionsRules = useGetFieldPermissions(priorityField);
  const getCategoryPermissionsRules = useGetFieldPermissions(categoryField);
  const getTitlePermissionsRules = useGetFieldPermissions(titleField);

  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);

  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
  const isTicketDisabled = isTicketLocked || !hasEditPermissions || isDisabled;
  const isPriorityDisabled = isTicketDisabled || getPriorityPermissionsRules?.disabled || priorityField?.readOnly;
  const isCategoryDisabled = isTicketDisabled || getCategoryPermissionsRules?.disabled || categoryField?.readOnly;
  const isNoTitleEditPermission = getTitlePermissionsRules?.disabled || titleField?.readOnly || !hasEditPermissions;

  const isTitleDisabled = isTicketDisabled || isNoTitleEditPermission;

  const isPreviewSR = getIsPreviewSr(srId);

  const priorityAttributesInput = useMemo(() => [{ fieldId: priorityField?.fieldId }], [priorityField?.fieldId]);
  const priorityList = useFieldAttributes(priorityAttributesInput)?.[0]?.data?.values;

  const titleText = useMemo(() => {
    if (isFloatingTicketPanel) {
      return typeof srTitle !== 'string' ? extractHeaderDefaultValue(template?.header, 'title') : srTitle;
    }
    return srTitle;
  }, [isFloatingTicketPanel, srTitle, template?.header]);
  const onBackButtonClick = () => {
    if (shouldUpdateSr) queryClient.invalidateQueries(['srId', String(srId)]);
    performance.mark(PERFORMANCE_MEASURE.TICKET.BACK_BTN_CLICK);
    if (isPreviewSR) {
      localStorage.setItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY, JSON.stringify(template));
      router.navigate({ to: '/spaces/template/new?previous=preview' });
    } else {
      router.navigate({ to: '/spaces' });
    }
  };

  const handleFieldChange = useCallback(
    async (updatedFieldValue, key) => {
      await handleSaveSR(updatedFieldValue, key);
    },
    [handleSaveSR],
  );

  useEffect(
    () => () => {
      clearTimeout(scheduleUpdateAssociatedFields.current);
    },
    [],
  );
  const srIdTitle = srId ? `${srTypeText} ${srId}` : srTypeNewText;

  const { showNoPermissionPopup } = useShowNoEditPermissionPopup();

  const { mutate: lockSR } = useHandleTicketLock(srId);

  const handleTicketLock = useCallback(() => {
    lockSR({ username: userAccount?.username, srId });
  }, [lockSR, userAccount?.username, srId]);

  const updateFieldValidation = useCallback(
    (fieldName, required, isValid) => {
      const id = srId || 'new';
      if (typeof fieldName !== 'undefined' && fieldName !== null) {
        dispatch(updateFieldValidationStatus({ srId: id, fieldName, required, isValid }));
      }
    },
    [srId, dispatch],
  );

  const handleChipIndicatorClick = () => {
    handleActiveTab(null, relatedItemsTabIndex);
    if (!isRelatedItemsTabSelected) {
      if (relationshipId === RELATIONSHIP_TYPES.CHILD) {
        trackEvents(TRACK_EVENTS.CHILD_INDICATOR_CLICK_IN_TICKET_PAGE);
      } else {
        trackEvents(TRACK_EVENTS.PARENT_INDICATOR_CLICK_IN_TICKET_PAGE);
      }
    }
  };
  return (
    <StyledHeader
      isFloatingTicketPanel={isFloatingTicketPanel}
      disabled={isTicketDisabled}
      className="sr-page-header"
      direction={direction}
      isRelatedItemsSelectedTab={isRelatedItemsTabSelected}
      isArchived={isArchived}
    >
      <SrLockHeader lockingDetails={lockingDetails} />
      {!isFloatingTicketPanel && !isPreviewSR && (
        <div className="incident-details-header" data-testid="incident-details-header">
          <GoBack onClickParent={onBackButtonClick} />
          <SrId title={srIdTitle} />
          {isSrChildOrParent && (
            <btn className="linked-sr-indicator-wrapper" onClick={handleChipIndicatorClick}>
              <SrLinkIcon />
              <span className="link-type">{relationshipText}</span>
            </btn>
          )}
          {isArchived && (
            <div className="archived-indicator" data-testid="archived-indicator">
              <ArchiveIcon />
              <span>{archivedText}</span>
            </div>
          )}
          {isMergedSR && (
            <div className="merged-sr-indicator" data-testid="merged-sr-indicator">
              <MergeIcon />
              <span>{getMergedSrIndicationText(srObject?.mergedAs)}</span>
            </div>
          )}
          {/* TODO:  get the data from the queue*/}
          <NextOrPrevSR srId={srId} />
        </div>
      )}

      <InlineFieldContainerWrapper
        className="middle-header"
        isFloatingTicketPanel={isFloatingTicketPanel}
        data-testid="ticket-header-title"
        data-cy="ticket-header-title"
      >
        <InlineFieldContainer
          limit={250}
          text={titleText}
          fieldName="title"
          handleDirty={handleDirty}
          handleSaveValue={handleFieldChange}
          CustomFieldComponent={TitleField}
          disabled={isTitleDisabled}
          onFocus={handleTicketLock}
          isNewField={isNewSR}
          required={titleRequired}
          autoFocus={autoFocus}
          showSummaryTooltip={!isNewSR}
          updateFieldValidation={updateFieldValidation}
          isFieldContentSize
          isAiEnabled={isAiEnabled}
          assistiveAI={assistiveAI}
          {...(isNoTitleEditPermission ? { handleDisabledClick: showNoPermissionPopup } : {})}
        />
      </InlineFieldContainerWrapper>
      <div className={`header-footer ${className}`} data-testid="ticket-header-footer">
        <div className="header-footer-left">
          <HeaderTags
            updateOnExit={setShouldUpdateSr}
            className={className}
            priority={priority}
            categoriesValues={categoriesValues}
            priorityList={priorityList}
            isPriorityDisabled={isPriorityDisabled}
            isCategoryDisabled={isCategoryDisabled}
            headerFields={template?.header}
            srType={srType}
            handleDirty={handleDirty}
            onFocus={handleTicketLock}
            handleSaveValue={handleFieldChange}
            categoryRequired={categoryRequired}
            priorityRequired={priorityRequired}
            updateFieldValidation={updateFieldValidation}
            forceSrId={forceSrId}
            onTemplateChanged={onTemplateChanged}
          />
        </div>
        <HeaderFooterRight
          updateOnExit={setShouldUpdateSr}
          srObject={srObject}
          className={className}
          onTicketStatusChange={onTicketStatusChange}
          onBackButtonClick={onBackButtonClick}
          isTicketDisabled={isTicketDisabled}
          updateFieldValidation={updateFieldValidation}
          template={template}
          isValidTicket={isValidTicket}
          isNewSR={isNewSR}
          isTicketPanel={isTicketPanel}
          isFloatingTicketPanel={isFloatingTicketPanel}
          handleFieldChange={handleFieldChange}
          handleTicketLock={handleTicketLock}
          handleDirty={handleDirty}
          forceStopuseStateStore={forceStopuseStateStore}
          srTypeText={srTypeText}
          isChildSr={isSrChildOrParent && relationshipId === RELATIONSHIP_TYPES.CHILD}
          srParentId={parentId}
          childSrsCount={childSrsCount}
        />
      </div>
    </StyledHeader>
  );
});

export default Header;
