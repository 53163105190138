import { useCallback, useEffect, lazy, Suspense, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useRouterState } from '@tanstack/react-router';
import { configureAbly, useChannel } from '@ably-labs/react-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'common/components/errorBoundary';
import EscalationPrompt from 'common/components/escalationPrompt';
import PendingComponentLoader from 'common/components/pendingComponentLoader';
import { getCookie, getShowNavBar, isMobileUserAgent, removeCookie } from 'common/utils/utils';
import { QUERIES_KEYS } from 'constant';
import { APP_CONSTANTS } from 'constants/app';
import { AUTH_CONSTANTS } from 'constants/auth';
import { CHANNEL_MESSAGE_TYPES } from 'constants/common';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import NavigationBar from 'features/navBar';
import { COMPANY_FIELD_KEY, SESSION_STORAGE } from 'features/queue/constants';
import {
  selectActiveView,
  selectColumnsOrder,
  selectDefaultView,
  selectOriginalView,
  setActiveView,
  setColumnsOrder,
  setDefaultView,
  setOriginalView,
} from 'features/queue/slice';
import {
  ASSET_FIELD_ID,
  ASSIGNED_GROUP_FIELD_ID,
  ASSIGNEE_FIELD_ID,
  CI_FIELD_ID,
  PROJECT_FIELD_ID,
  REQUEST_USER_FIELD_ID,
  TASK_FIELD_ID,
} from 'features/queue/grid/constants';
import {
  FIRST_LEVEL_CATEGORY_FIELD_ID,
  SECOND_LEVEL_CATEGORY_FIELD_ID,
  THIRD_LEVEL_CATEGORY_FIELD_ID,
} from 'features/header/categories/constants';
import { getViewInitializedColumnConfig } from 'features/queue/utils';
import { BASIC_EDITION, LIMITED_DATA_BY_COMPANY_ALL_FIELDS } from 'features/settings/constants';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useFeatureFlagQuery } from 'remote-state/featureFlagsHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { useGetAgentChatbotConfig } from 'remote-state/chatbotHook';
import { handleLocalHostUserDetails, isLocalHost } from 'services/localhost';
import { details, selectActiveUser } from 'store/userSlice';
import { clearStorage } from 'features/Logout/utils';
import { showPrompt } from 'store/messagePromptSlice';
import { getUserMobileLink } from '../../services/userService';
import ReloadPagePrompt from './reloadPagePrompt';
import { toggle, selectIsChatbotOpen, openChatbot, collapse } from './slice';
import { StyledContainer } from './style';
import useTexts from './useTexts';

const AdminChatbot = lazy(() => import('features/adminChatbot'));

const editions = new Map([
  [0, 'Basic Edition'],
  [2, 'Full Edition'],
]);

async function isMobileUserAgentRedirected(userAgent, userRole) {
  if (isMobileUserAgent(userAgent) && userRole === AUTH_CONSTANTS.ADMIN) {
    const res = await getUserMobileLink();
    if (res?.mobileAppServerUrl?.length > 0) {
      window.location.href = res.mobileAppServerUrl;
      return true;
    }
  }
  return false;
}

function getBrowserType() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    return 'Internet Explorer';
  }
  if (userAgent.indexOf('Edge') !== -1) {
    return 'Microsoft Edge';
  }
  if (userAgent.indexOf('Firefox') !== -1) {
    return 'Mozilla Firefox';
  }
  if (userAgent.indexOf('Chrome') !== -1) {
    return 'Google Chrome';
  }
  if (userAgent.indexOf('Safari') !== -1) {
    return 'Apple Safari';
  }
  if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    return 'Opera';
  }
  return 'Unknown browser';
}

const handleBeforeLoad = async ({ currentUser, dispatch, isAiEnabled, edition, defaultUi }) => {
  if (isLocalHost()) {
    if (currentUser?.isAuth) {
      if (window.permissionInitialized) return;
      window.permissionInitialized = true;
    } else {
      handleLocalHostUserDetails(dispatch);
    }
    return;
  }
  const isClassicRedirected = localStorage.getItem(APP_CONSTANTS.IS_CLASSIC_REDIRECTED);
  if (defaultUi === 'CLASSIC' && !isClassicRedirected) {
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
    localStorage.setItem(APP_CONSTANTS.IS_CLASSIC_REDIRECTED, 'true');
    window.open(window.location.origin, '_self', 'noreferrer');
    return;
  }
  if (currentUser?.roles[0] === AUTH_CONSTANTS.END_USER) {
    window.location.href = '/servicePortal';
    return;
  }

  const isQAMode =
    localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) || localStorage.getItem(APP_CONSTANTS.IS_QA_MODE) === null;

  if (currentUser?.isAuth) {
    window.pendo?.initialize({
      visitor: {
        id: `${localStorage.getItem('account_id')}_${currentUser?.userId}`,
        visitorId: `${localStorage.getItem('account_id')}_${currentUser?.userId}`,
        accountId: `${localStorage.getItem('account_id')}`,
        userLanguage: currentUser?.language,
        calculatedRole: currentUser?.calculatedRole,
        browserType: getBrowserType(),
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        deviceMemory: navigator?.deviceMemory,
      },
      account: {
        id: localStorage.getItem('account_id'),
        isSpaces: true,
        isQAMode,
        isOnDemand: true,
        edition: editions.get(edition) ?? 'Unknown',
        isAI: isAiEnabled,
      },
    });
    if (window.permissionInitialized) return;
    window.permissionInitialized = true;
  }

  isMobileUserAgentRedirected(navigator.userAgent, currentUser?.roles[0]);
};

const PrivatePagesComponentWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectActiveUser);
  const userDetails = useSelector(details);
  const { isFetched, isFetching } = useApplicationData(QUERIES_KEYS.APPLICATION_DATA, { forceCall: true });
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const { data: defaultUi } = useApplicationData(QUERIES_KEYS.DEFAULT_UI);
  const showContent = isLocalHost() ? isFetched : isFetched && !isFetching;

  useEffect(() => {
    handleBeforeLoad({ currentUser, userDetails, dispatch, isAiEnabled, edition, defaultUi });
  }, [currentUser, userDetails, dispatch, isAiEnabled, edition, defaultUi]);

  return showContent ? <>{children}</> : <PendingComponentLoader />;
};

const accountId = localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY);
const QUEUE_CHANNEL_NAME = accountId;
const targetChannel = localStorage.getItem(APP_CONSTANTS.ACCOUNT_TARGET);

export default function LoggedInRoutesPanel({ children }) {
  const dispatch = useDispatch();
  const [isReloadPromptOpen, setReloadPromptOpen] = useState(false);
  useApplicationData(QUERIES_KEYS.APPLICATION_DATA);
  const router = useRouter();
  const routerSearch = useRouterState({
    select: (state) => state.location.searchStr,
  });

  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: isAdminChatbotEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.ADMIN_CHATBOT_ENABLED,
    defaultValue: false,
  });
  const { data: isAiAdministrator } = useGetAIConfigData(QUERIES_KEYS.IS_AI_ADMINISTRATOR);
  const { data: agentChatbot } = useGetAgentChatbotConfig();
  const isAgentChatbotEnabled = isAiAdministrator || agentChatbot?.configuration?.isChatbotEnable;
  const displayCopilot = isAgentChatbotEnabled && isAiEnabled && isAdminChatbotEnabled;

  const activeView = useSelector(selectActiveView);
  const defaultView = useSelector(selectDefaultView);
  const originalView = useSelector(selectOriginalView);
  const columnsOrder = useSelector(selectColumnsOrder);
  const isChatbotOpen = useSelector(selectIsChatbotOpen);
  const queryClient = useQueryClient();
  const [menuExpanded, setMenuExpanded] = useState(
    JSON.parse(localStorage.getItem(APP_CONSTANTS.IS_MENU_EXPANDED)) === true,
  );
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);
  const isBasicEdition = edition === BASIC_EDITION;
  const isLocalEnvironment = isLocalHost();
  const userDetails = useSelector(details);
  const userDetailsRef = useRef(userDetails);
  const {
    updatedPermissions: { title, description, refreshPageButton },
  } = useTexts();

  useEffect(() => {
    userDetailsRef.current = userDetails;
  }, [userDetails]);

  configureAbly({
    autoConnect: !isLocalEnvironment, // If working locally with Ably sockets enabled - comment this line out!
    authCallback: async (tokenParams, callback) => {
      let tokenRequest;
      try {
        const response = await fetch('/spaces/api/authentication/websocket/token');
        // const response = await fetch('websocket/token'); // for dev only
        tokenRequest = await response.json();
        tokenRequest['clientId'] = getCookie(APP_CONSTANTS.SESSION_ID);
      } catch (e) {
        console.error('Error occurred during fetching token for ably', e);
        callback(e, null);
      }
      callback(null, tokenRequest);
    },
    recover(_, cb) {
      cb(true);
    },
  });

  useChannel(targetChannel, ({ name, data }) => {
    setReloadPromptOpen(true);
    console.log('Websocket Target Channel: useChannel event commited', { target: targetChannel, name, data });
  });

  useChannel(QUEUE_CHANNEL_NAME, ({ name, data }) => {
    if (name === CHANNEL_MESSAGE_TYPES.UPDATE_SETTINGS) {
      //We clean attributes with timeout because Wescoket works much faster than settings changes getting applied on backend.
      //In that case we are not getting updated values on attributes but old one.
      setTimeout(() => {
        switch (data?.type) {
          case 'defaultTemplate':
            queryClient.invalidateQueries({ queryKey: ['templatesList'] });
            queryClient.invalidateQueries({ queryKey: ['defaultTemplate'] });
            break;
          case 'applicationData':
            queryClient.invalidateQueries([QUERIES_KEYS.APPLICATION_DATA]);
            break;
          case 'srSubTab':
            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.ATTRIBUTES, data?.listName] });
            localStorage.setItem('cacheKey', String(Math.random()));
            queryClient.invalidateQueries({ queryKey: ['fieldAttribute'] });
            break;
          case 'uploadTranslationFile':
            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.RESOURCE_BUNDLE] });
            break;
          case 'uploadStatusSettings':
            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.STATUS_SETTINGS] });
            queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.FIELD_ATTRIBUTE, 16] });
            break;
          case LIMITED_DATA_BY_COMPANY_ALL_FIELDS: {
            const fieldIdsToClearCache = [
              REQUEST_USER_FIELD_ID,
              ASSIGNEE_FIELD_ID,
              ASSIGNED_GROUP_FIELD_ID,
              PROJECT_FIELD_ID,
              TASK_FIELD_ID,
              ASSET_FIELD_ID,
              CI_FIELD_ID,
              FIRST_LEVEL_CATEGORY_FIELD_ID,
              SECOND_LEVEL_CATEGORY_FIELD_ID,
              THIRD_LEVEL_CATEGORY_FIELD_ID,
            ];

            if (data.username === userDetailsRef?.current?.userName) {
              fieldIdsToClearCache.forEach((fieldId) => {
                queryClient.removeQueries({ queryKey: [QUERIES_KEYS.FIELD_ATTRIBUTE, fieldId] });
              });

              queryClient.removeQueries([QUERIES_KEYS.ATTRIBUTES]);

              dispatch(
                showPrompt({
                  title,
                  descriptionText: description,
                  btnOkText: refreshPageButton,
                  showCancelBtn: false,
                  showIcon: true,
                  onClickFunctionName: LIMITED_DATA_BY_COMPANY_ALL_FIELDS,
                  okCb: () => {
                    window.location.reload();
                  },
                }),
              );
            }
            break;
          }
          default:
            localStorage.setItem('cacheKey', String(Math.random()));
            queryClient.invalidateQueries({ queryKey: ['fieldAttribute'] });
            break;
        }
      }, 2000);
    }
  });

  const showNavBar = getShowNavBar(router);

  const toggleNavBarCollapse = useCallback(() => {
    localStorage.setItem(APP_CONSTANTS.IS_MENU_EXPANDED, !menuExpanded);
    setMenuExpanded(!menuExpanded);
    setTimeout(() => dispatch(toggle()));
  }, [dispatch, menuExpanded]);

  const clearStorageByEdition = useCallback(() => {
    if (columnsOrder) {
      dispatch(setColumnsOrder(columnsOrder.filter((column) => column.fieldName !== COMPANY_FIELD_KEY)));
    }

    let columnsConfig;

    if (defaultView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(defaultView.columnsConfig);
      dispatch(
        setDefaultView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }

    if (originalView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(originalView.columnsConfig);

      dispatch(
        setOriginalView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }

    if (activeView?.columnsConfig) {
      columnsConfig = getViewInitializedColumnConfig(activeView.columnsConfig);

      dispatch(
        setActiveView((prev) => ({
          ...prev,
          columnsConfig,
        })),
      );
    }
  }, [columnsOrder, activeView, defaultView, originalView, dispatch]);

  useEffect(() => {
    if (!edition) return;

    const storedEdition = sessionStorage.getItem(SESSION_STORAGE.edition);
    if (isBasicEdition && (storedEdition === null || Number(edition) !== Number(storedEdition))) {
      clearStorageByEdition();
    }
    if (!storedEdition || Number(edition) !== Number(storedEdition)) {
      sessionStorage.setItem(SESSION_STORAGE.edition, edition);
    }
  }, [edition, isBasicEdition, clearStorageByEdition]);

  const handleReloadPromptClick = () => {
    setReloadPromptOpen(false);
    clearStorage(true, true);
    queryClient.resetQueries();
    setTimeout(() => {
      window.location.reload(true);
    }, 100);
  };

  const AdminChatbotFallback = () => (
    <div style={{ width: '30%' }}>
      <PendingComponentLoader />
    </div>
  );

  useEffect(
    () => () => {
      // Clean up Pendo on component unmount
      if (window.pendo) {
        if (typeof window.pendo.clearGuideMemory === 'function') {
          window.pendo.clearGuideMemory();
        }
        if (typeof window.pendo.clearResourceCenter === 'function') {
          window.pendo.clearResourceCenter();
        }
      }
    },
    [],
  );

  const handleAIBuilderOpened = useCallback(() => {
    const isAIBuilderOpened = routerSearch.includes('ai-builder');
    const isChatbotClosed = localStorage.getItem(APP_CONSTANTS.IS_CHATBOT_CLOSED);
    const isChatbotOpen = displayCopilot && !(isChatbotClosed === 'true');

    if (isAIBuilderOpened) {
      dispatch(openChatbot(false));
      localStorage.setItem(APP_CONSTANTS.IS_MENU_EXPANDED, false);
      setMenuExpanded(false);
      setTimeout(() => dispatch(collapse()), 0);
    } else {
      dispatch(openChatbot(isChatbotOpen));
    }
  }, [dispatch, displayCopilot, routerSearch]);

  useEffect(() => {
    handleAIBuilderOpened();
  }, [handleAIBuilderOpened]);

  return (
    <PrivatePagesComponentWrapper>
      <ReloadPagePrompt onClick={handleReloadPromptClick} open={isReloadPromptOpen} />
      <ErrorBoundary>
        {showNavBar ? (
          <>
            <NavigationBar toggleNavBarCollapse={toggleNavBarCollapse} navBarCollapsed={!menuExpanded} />
            <StyledContainer className={isChatbotOpen ? 'chatbot-open' : ''}>
              {children}
              {isChatbotOpen && (
                <Suspense fallback={<AdminChatbotFallback />}>
                  <AdminChatbot />
                </Suspense>
              )}
            </StyledContainer>
          </>
        ) : (
          { children }
        )}
        <EscalationPrompt />
      </ErrorBoundary>
    </PrivatePagesComponentWrapper>
  );
}
